export const getLicenseStatus = (status) => {
  switch (status) {
    case "ACT":
      return "Activated";
    case "EXP":
      return "Expired";
    case "RVK":
      return "Revoked";
    default:
      return "Unknown";
  }
};

export const getLicenseStatusColor = (status) => {
  switch (status) {
    case "Activated":
      return "#3FD3BD";
    case "Expired":
      return "#DE0B15";
    default:
      return "#B5B5B5";
  }
};

export const getLicenseProvider = (licenseKey) => {
  // eslint-disable-next-line quotes, prefer-regex-literals
  const re = new RegExp(`^\\w{2}-\\w{4}-\\w{5}-\\w{5}-\\w{5}-\\w{5}-\\w{5}$`);
  if (re.exec(licenseKey)) {
    return "Trend Micro";
  }
  return "TXOne";
};

export const isEdgeSN = (productSerialNumber) => {
  // eslint-disable-next-line quotes, prefer-regex-literals
  const edgeSNRegex = new RegExp("^(tx|tm|sn)(g|f|p|m|l)[0-9]{10,11}$", "i");
  return edgeSNRegex.test(productSerialNumber);
};
