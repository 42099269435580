import React from "react";
import { IconButton } from "@mui/material";

import { ReactComponent as XImg } from "assets/images/icon_c_x.svg";

export function TwitterIcon() {
  return (
    <IconButton href="https://twitter.com/TXOneNetworks" target="_blank" rel="noopener noreferrer">
      <XImg width={30} height={30} />
    </IconButton>
  );
}
export default TwitterIcon;
